import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ModulesComponent } from './modules/modules.component';
import { WelcomeComponent } from './modules/welcome/welcome.component';
import { RegisterInvitationComponent } from './register-invitation/register-invitation.component';
import { ProfileComponent } from './user/profile/profile.component';
import { EntityComponent } from './common/entity/entity.component';
import { DataItemComponent } from './common/entity/data-item/data-item.component';
import { GlobalSearchComponent } from './modules/global-search/global-search.component';

const routes: Routes = [
  {
    path: '',
    component: ModulesComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', component: WelcomeComponent, data: {} },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { breadcrumb: 'Profile' },
      },
      {
        path: 'entity',
        component: EntityComponent,
        data: { breadcrumb: 'Entity' },
      },
      {
        path: 'entity/item/:entity/:item',
        component: DataItemComponent,
        data: { breadcrumb: 'Item' },
      },
      {
        path: 'fulladmin',
        loadChildren: () =>
          import('./modules/fulladmin/fulladmin.module').then(
            (m) => m.FulladminModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'full-admin', breadcrumb: 'Full-Admin' },
      },
      {
        path: 'localadmin',
        loadChildren: () =>
          import('./modules/localadmin/localadmin.module').then(
            (m) => m.LocaladminModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'local-admin,full-admin', breadcrumb: 'Local-Admin' },
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('./modules/chat/chat.module').then((m) => m.ChatModule),
        data: { breadcrumb: 'Chat' },
      },
      {
        path: 'customentity',
        loadChildren: () =>
          import('./modules/crm/crm.module').then((m) => m.CrmModule),
        canActivate: [AuthGuardService],
        data: { role: 'customentity', breadcrumb: 'customentity' },
      },
      {
        path: 'task',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'tasks', breadcrumb: 'task' },
      },
      {
        path: 'document',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'documents', breadcrumb: 'document' },
      },
      {
        path: 'project',
        loadChildren: () =>
          import('./modules/projects/projects.module').then(
            (m) => m.ProjectsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'projects', breadcrumb: 'project' },
      },

      {
        path: 'suppliers',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'supplier', breadcrumb: 'suppliers' },
      },

      {
        path: 'service',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'service', breadcrumb: 'service' },
      },
      {
        path: 'installations',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'installations', breadcrumb: 'installations' },
      },
      {
        path: 'salesforecast',
        loadChildren: () =>
          import('./modules/salesforecast/salesforecast.module').then(
            (m) => m.SalesforecastModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'salesforecast', breadcrumb: 'salesforecast' },
      },

      {
        path: 'contact',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'contact', breadcrumb: 'contact' },
      },
      {
        path: 'search',
        component: GlobalSearchComponent,
        data: { breadcrumb: 'Search' },
      },
      {
        path: 'country',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'country', breadcrumb: 'country' },
      },
      {
        path: 'competitor',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'competitor', breadcrumb: 'competitor' },
      },
      {
        path: 'account',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'account', breadcrumb: 'account' },
      },
      {
        path: 'lead',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'lead', breadcrumb: 'lead' },
      },
      {
        path: 'product',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'product', breadcrumb: 'product' },
      },
      {
        path: 'order',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'order', breadcrumb: 'order' },
      },
      {
        path: 'opportunity',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'opportunity', breadcrumb: 'opportunity' },
      },
      {
        path: 'invoice',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'invoice', breadcrumb: 'invoice' },
      },

      {
        path: 'quote',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'quote', breadcrumb: 'quote' },
      },
      {
        path: 'productconf',
        loadChildren: () =>
          import('./modules/productconf/productconf.module').then(
            (m) => m.ProductconfModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'productconf', breadcrumb: 'productconf' },
      },
      {
        path: 'semiprod',
        loadChildren: () =>
          import('./modules/semiprod/semiprod.module').then(
            (m) => m.SemiprodModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'semiprod', breadcrumb: 'semiprod' },
      },

      {
        path: 'report',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'report', breadcrumb: 'report' },
      },
      {
        path: 'colleagues',
        loadChildren: () =>
          import('./modules/colleagues/colleagues.module').then(
            (m) => m.ColleaguesModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'colleagues', breadcrumb: 'colleagues' },
      },
      {
        path: 'purchasingorder',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'purchasingorder', breadcrumb: 'purchasingorder' },
      },
      {
        path: 'productionorder',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'productionorder', breadcrumb: 'productionorder' },
      },
      {
        path: 'mrp',
        loadChildren: () =>
          import('./modules/mrp/mrp.module').then((m) => m.MrpModule),
        canActivate: [AuthGuardService],
        data: { role: 'mrp', breadcrumb: 'mrp' },
      },
      {
        path: 'productionschedule',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'productionschedule', breadcrumb: 'productionschedule' },
      },
      {
        path: 'channel',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'channel', breadcrumb: 'channel' },
      },
      {
        path: 'approval',
        loadChildren: () =>
          import('./modules/approval/approval.module').then(
            (m) => m.ApprovalModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'approval', breadcrumb: 'approval' },
      },

      {
        path: 'emploee',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'emploee', breadcrumb: 'emploee' },
      },

      {
        path: 'hrticket',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'hrticket', breadcrumb: 'hrticket' },
      },

      {
        path: 'absence',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'absence', breadcrumb: 'absence' },
      },

      {
        path: 'resume',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'resume', breadcrumb: 'resume' },
      },

      {
        path: 'interview',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'interview', breadcrumb: 'interview' },
      },
      {
        path: 'training',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'training', breadcrumb: 'training' },
      },
      {
        path: 'shift',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'shift', breadcrumb: 'shift' },
      },
      {
        path: 'calendar',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'calendar', breadcrumb: 'calendar' },
      },
      {
        path: 'address',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'address', breadcrumb: 'address' },
      },
      {
        path: 'machinery',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'machinery', breadcrumb: 'machinery' },
      },
      {
        path: 'spare_part',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'spare_part', breadcrumb: 'spare_part' },
      },
      {
        path: 'maintenance_file',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'maintenance_file', breadcrumb: 'maintenance_file' },
      },
      {
        path: 'breakdown',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'breakdown', breadcrumb: 'breakdown' },
      },
      {
        path: 'repair_order',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'repair_order', breadcrumb: 'repair_order' },
      },
      {
        path: 'prodrecipe',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'prodrecipe', breadcrumb: 'prodrecipe' },
      },
      {
        path: 'statistics',
        loadChildren: () =>
          import('./modules/statistics/statistics.module').then(
            (m) => m.StatisticsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'statistics', breadcrumb: 'statistics' },
      },
      {
        path: 'meetings',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'meetings', breadcrumb: 'meetings' },
      },
      {
        path: 'expense',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'expense', breadcrumb: 'expense' },
      },
      {
        path: 'expensesheet',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: { role: 'expensesheet', breadcrumb: 'expensesheet' },
      },
      {
        path: 'workflow_template',
        loadChildren: () =>
          import('./modules/workflow-templates/workflow-templates.module').then(
            (m) => m.WorkflowTemplatesModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'workflow_template', breadcrumb: 'workflow_template' },
      },
      {
        path: 'workflow',
        loadChildren: () =>
          import('./modules/workflows/workflows.module').then(
            (m) => m.WorkflowsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'workflow', breadcrumb: 'workflow' },
      },
      {
        path: 'erp_warehouses',
        loadComponent: () =>
          import(
            './components/erp/warehouses/erp-wh-docs-list/erp-wh-docs-list.component'
          ).then((c) => c.ErpWhDocsListComponent),
        canActivate: [AuthGuardService],
        data: { role: 'erp_warehouses', breadcrumb: 'erp_warehouses' },
      },
      {
        path: 'erp_document/:doc',
        loadComponent: () =>
          import(
            './components/erp/warehouses/erp-wh-docs-list/erp-ws-new-doc/erp-ws-new-doc.component'
          ).then((c) => c.ErpWsNewDocComponent),
        data: { role: 'erp_warehouses', breadcrumb: 'erp_warehouses' },
      },
      {
        path: 'erp_trades',
        loadComponent: () =>
          import(
            './components/erp/trades/erp-fin-docs-list/erp-fin-docs-list.component'
          ).then((c) => c.ErpFinDocsListComponent),
        canActivate: [AuthGuardService],
        data: { role: 'erp_trades', breadcrumb: 'erp_trades' },
      },
      {
        path: 'erp_fin_document/:doc',
        loadComponent: () =>
          import(
            './components/erp/trades/erp-fin-new-doc/erp-fin-new-doc.component'
          ).then((c) => c.ErpFinNewDocComponent),
        canActivate: [AuthGuardService],
        data: { role: 'erp_trades', breadcrumb: 'erp_trades' },
      },
      {
        path: 'erp_account',
        loadComponent: () =>
          import(
            './components/erp/account/erp-moneyaccount-docs-list/erp-moneyaccount-docs-list.component'
          ).then((c) => c.ErpMoneyaccountDocsListComponent),
        canActivate: [AuthGuardService],
        data: { role: 'erp_account', breadcrumb: 'erp_account' },
      },
      {
        path: 'erp_money_account/:doc',
        loadComponent: () =>
          import(
            './components/erp/account/erp-moneyaccount-new-doc/erp-moneyaccount-new-doc.component'
          ).then((c) => c.ErpMoneyaccountNewDocComponent),
        canActivate: [AuthGuardService],
        data: { role: 'erp_account', breadcrumb: 'erp_account' },
      },
      {
        path: 'erp_warehouse_movements',
        loadComponent: () =>
          import(
            './components/erp/warehouse_movements/erp-cards-product/erp-cards-product.component'
          ).then((c) => c.ErpCardsProductComponent),
        canActivate: [AuthGuardService],
        data: {
          role: 'erp_warehouse_movements',
          breadcrumb: 'erp_warehouse_movements',
        },
      },
      {
        path: 'erp_account_card',
        loadComponent: () =>
          import(
            './components/erp/account_card/erp-cards-account/erp-cards-account.component'
          ).then((c) => c.ErpCardsAccountComponent),
        canActivate: [AuthGuardService],
        data: {
          role: 'erp_account_card',
          breadcrumb: 'erp_account_card',
        },
      },
      {
        path: 'erp_supplier_card',
        loadComponent: () =>
          import(
            './components/erp/supplier_card/erp-cards-supplier/erp-cards-supplier.component'
          ).then((c) => c.ErpCardsSupplierComponent),
        canActivate: [AuthGuardService],
        data: {
          role: 'erp_supplier_card',
          breadcrumb: 'erp_supplier_card',
        },
      },
      {
        path: 'erp_money_account_card',
        loadComponent: () =>
          import(
            './components/erp/money_account_card/erp-cards-money-account/erp-cards-money-account.component'
          ).then((c) => c.ErpCardsMoneyAccountComponent),
        canActivate: [AuthGuardService],
        data: {
          role: 'erp_money_account_card',
          breadcrumb: 'erp_money_account_card',
        },
      },
      {
        path: 'erp_vat_card',
        loadComponent: () =>
          import(
            './components/erp/vat_card/erp-cards-vat/erp-cards-vat.component'
          ).then((c) => c.ErpCardsVatComponent),
        canActivate: [AuthGuardService],
        data: {
          role: 'erp_vat_card',
          breadcrumb: 'erp_vat_card',
        },
      },
      {
        path: 'pickingorder',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: {
          role: 'pickingorder',
          breadcrumb: 'pickingorder',
        },
      },
      {
        path: 'receivinglist',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: {
          role: 'receivinglist',
          breadcrumb: 'receivinglist',
        },
      },
      {
        path: 'inventory',
        loadComponent: () =>
          import('./components/entity-list/entity-list.component').then(
            (c) => c.EntityListComponent
          ),
        canActivate: [AuthGuardService],
        data: {
          role: 'inventory',
          breadcrumb: 'inventory',
        },
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'invitation', component: RegisterInvitationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
