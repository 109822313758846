import { LoginService } from '../../services/login.service';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EntityService } from 'src/app/services/entity.service';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from '../../services/company.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit {
  tabCols: number;
  selectedTabIndex = 0;
  showTabs = false;
  language = '';

  constructor(
    public readonly loginService: LoginService,
    private readonly breakpointObserver: BreakpointObserver,
    public readonly translateService: TranslateService,
    public readonly entityService: EntityService,
    public readonly dialog: MatDialog,
    public readonly companyService: CompanyService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.language = this.loginService.getLoginUser().lang;
    this.checkForFetchedModules();
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.HandsetLandscape,
      ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.tabCols = 2;
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.tabCols = 4;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.tabCols = 6;
        } else if (
          result.breakpoints[Breakpoints.Large] ||
          result.breakpoints[Breakpoints.XLarge]
        ) {
          this.tabCols = 10;
        }
      });
  }

  setTabState(tabId: number): void {
    this.selectedTabIndex = tabId;
    localStorage.setItem('tabIndex', String(this.selectedTabIndex));
  }

  private getSavedTabState() {
    if (localStorage.getItem('tabIndex')) {
      this.selectedTabIndex = Number(localStorage.getItem('tabIndex'));
    }
  }

  checkForFetchedModules() {
    this.companyService.getSavedTabState
      .pipe(
        filter((res) => res === true),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((modules) => {
        this.showTabs = true;
        this.getSavedTabState();
      });
  }
}
