import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { LoginService } from '../services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../services/notification.service';
import { LayoutService } from '../services/layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  email: string;
  password: string;
  loginError: boolean = false;
  isHandset = this.layoutService.isHandset;

  constructor(
    public loginService: LoginService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly layoutService: LayoutService
  ) {}

  public login() {
    let formData = new FormData();
    formData.append('email', this.email);
    formData.append('password', this.password);

    this.loginService
      .login(formData)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.success) {
          this.loginError = false;
          this.setUpSession(response);
        } else {
          this.loginError = true;
        }
      });
  }

  private setUpSession(response: any) {
    this.loginService.setSession(response.success.token, response.success.user);
    this.notificationService.showWelcomeMessage(
      response.success.user.firstname
    );
    this.translateService.use(response.success.user.lang);
    this.router.navigate(['']);
  }
}
